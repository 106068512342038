<template>
	<div class="VFX">
		<div class="header">
			<img src="@/assets/images/VFX_header.png" alt="">
			<div class="inner animated fadeIn">
				<p>{{$t('lang.VFX.header1')}}</p>
				<p>{{$t('lang.VFX.header2')}}</p>
			</div>
		</div>
		<div class="main">
			<p class="title">{{$t('lang.VFX.text1')}}</p>
			<p v-for="(item,index) of $t('lang.VFX.text2')" :key="index">{{item}}</p>
		</div>
		<HlySwipe :list="list" />
	</div>
</template>

<script>
import HlySwipe from "@/components/HlySwipe.vue";

export default {
  name: "VFX",
  components: {
    HlySwipe
  },
  data() {
    return {
      list: [
        { img: require("@/assets/images/VFX/1.jpg") },
        { img: require("@/assets/images/VFX/2.jpg") },
        { img: require("@/assets/images/VFX/3.jpg") },
        { img: require("@/assets/images/VFX/4.jpg") }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.VFX {
  .main {
    width: 100%;
    margin: 0 auto;
    padding: 0 17.7vw;
    p {
      text-align: left;
      font-size: 18px;
      opacity: 0.8;
      line-height: 30px;
      font-family: "OpenSansLight";
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 32px;
      margin: 120px auto 30px;
      opacity: 1;
      font-family: "OpenSansRegular";
    }
    .add-text {
      font-size: 18px;
      opacity: 0.8;
    }
  }
}
</style>

